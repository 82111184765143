import { motion } from 'framer-motion';
import React,{ useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoIosInformationCircleOutline } from 'react-icons/io';

import { clsm, isElementsOverlapping } from '../../../utils';
import { DEFAULT_PROFILE_VIEW_TRANSITION } from '../../../constants';
import { useChannel } from '../../../contexts/Channel';
import { usePlayerContext } from '../contexts/Player';
import { useProfileViewAnimation } from '../contexts/ProfileViewAnimation';
import { useUser } from '../../../contexts/User';
import FollowButton from './FollowButton';
import PlayerOverlay from './PlayerOverlay';
import ProfileViewMenu from './ProfileViewMenu';
import UserAvatar from '../../../components/UserAvatar';
import { POPUP_ID } from './Controls/RenditionSetting';
import useResize from '../../../hooks/useResize';
import { useResponsiveDevice } from '../../../contexts/ResponsiveDevice';
import { createAnimationProps } from '../../../helpers/animationPropsHelper';
import Button from '../../../components/Button';
const TipList = [
  {
    value: 10,
    label: '10',
    tipContent: '$10'
  },
  {
    value: 25,
    label: '25',
    tipContent: '$25'
  },
  {
    value: 50,
    label: '50',
    tipContent: '$50'
  },
  {
    value: 100,
    label: '100',
    tipContent: '$100'
  },
  {
    value: 150,
    label: '150',
    tipContent: '$150'
  },
  {
    value: 200,
    label: '200',
    tipContent: '$200'
  },
  {
    value: 500,
    label: '500',
    tipContent: '$500'
  },
  {
    value: 600,
    label: '600',
    tipContent: '$600'
  },{
    value: 700,
    label: '700',
    tipContent: '$700'
  },{
    value: 800,
    label: '800',
    tipContent: '$800'
  },
  
  
  

];
const getHeaderButtonClasses = (shouldRemoveZIndex = false) => {
  return clsm([
    'flex',
    'items-center',
    'space-x-2',
    'shrink-0',
    'pointer-events-all',
    !shouldRemoveZIndex && 'z-10'
  ]);
};
export const HAIRLINE_DIVIDER_CLASSES = [
  'w-full',
  'h-0.5',
  'rounded',
  'flex-shrink-0',
  'bg-lightMode-gray-light-hover',
  'dark:bg-darkMode-gray-hover'
];
const PlayerHeader = ({ avatarSrc, color, username, openPopupIds }) => {
  const {
    getProfileViewAnimationProps,
    headerAnimationControls,
    isProfileViewAnimationEnabled,
    isProfileViewExpanded,
    shouldAnimateProfileView,
    toggleProfileView
  } = useProfileViewAnimation();
  const { isLandscape } = useResponsiveDevice();

  const isRenditionSettingPopupExpanded = !!openPopupIds.find(
    (openPopupId) => openPopupId === POPUP_ID
  );
  const [shouldRemoveFollowButtonZIndex, setShouldRemoveFollowButtonZIndex] =
    useState(false);
  const [followButtonRefState, setFollowButtonRefState] = useState();
  const { qualitiesContainerRef } = usePlayerContext();

  useResize(() => {
    if (
      isRenditionSettingPopupExpanded &&
      followButtonRefState &&
      qualitiesContainerRef?.current
    ) {
      if (
        !shouldRemoveFollowButtonZIndex &&
        isElementsOverlapping(
          followButtonRefState,
          qualitiesContainerRef?.current
        )
      ) {
        setShouldRemoveFollowButtonZIndex(true);
      }

      if (
        shouldRemoveFollowButtonZIndex &&
        !isElementsOverlapping(
          followButtonRefState,
          qualitiesContainerRef?.current
        )
      ) {
        setShouldRemoveFollowButtonZIndex(false);
      }
    }
  });

  const qualitiesContainerInitialHeight =
    qualitiesContainerRef?.current?.clientHeight;

  // On mount
  useEffect(() => {
    if (
      isRenditionSettingPopupExpanded &&
      followButtonRefState &&
      qualitiesContainerRef?.current
    ) {
      if (
        isElementsOverlapping(
          followButtonRefState,
          qualitiesContainerRef?.current
        ) ||
        qualitiesContainerRef?.current.clientHeight >
          qualitiesContainerInitialHeight
      ) {
        setShouldRemoveFollowButtonZIndex(true);
      }
    }
  }, [
    followButtonRefState,
    qualitiesContainerRef,
    isRenditionSettingPopupExpanded,
    qualitiesContainerInitialHeight,
    shouldRemoveFollowButtonZIndex
  ]);

  useEffect(() => {
    if (!isRenditionSettingPopupExpanded) {
      setShouldRemoveFollowButtonZIndex(false);
    }
  }, [isRenditionSettingPopupExpanded]);

  const { isOverlayVisible } = usePlayerContext();
  const { isSessionValid } = useUser();
  const { channelData: { isLive } = {} } = useChannel();
  const layoutDependency = useRef(null);
  const animationDuration = DEFAULT_PROFILE_VIEW_TRANSITION.duration;
  const shouldShowHeaderOverlay =
    isOverlayVisible || isLive === false || isProfileViewExpanded;

  if (shouldAnimateProfileView.current) layoutDependency.current = Date.now();

  const getPlayerHeaderProfileViewAnimationProps = useCallback(
    (variantStyles) =>
      getProfileViewAnimationProps(headerAnimationControls, variantStyles),
    [getProfileViewAnimationProps, headerAnimationControls]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [CustomTip, setCustomTip] = useState(false);
  const [tipAmount, settipAmount] = useState('');
  const [tipDescription, settipDiscription] = useState('');
  const [tipCost, settipCost] = useState('');

  const buttonRef = useRef(null);

  const toggleModal = () => {
    
    setIsOpen(!isOpen);

    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setModalPosition({
        top: rect.top + window.scrollY - rect.height, // Position above the button
        left: rect.left + window.scrollX
      });
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  return (
    <div
      className={clsm(
        'absolute',
        'w-full',
        'top-0',
        'flex',
        'pt-8',
        'px-8',
        'lg:pt-4',
        'lg:px-4',
        'pointer-events-none',
        'items-center',
        isProfileViewExpanded
          ? ['justify-center', 'flex-col']
          : ['justify-between', 'flex-row']
      )}
    >
      <motion.div
        layout="position"
        layoutDependency={layoutDependency.current}
        className={clsm(getHeaderButtonClasses())}
        {...getPlayerHeaderProfileViewAnimationProps({
          expanded: { y: 64, desktop: { y: 48 } },
          collapsed: { y: 0 }
        })}
      >
        <button
          className={clsm([
            'group',
            'flex',
            'rounded-[50%]',
            'focus:outline-none',
            'focus:ring-2',
            'focus:ring-white'
          ])}
          disabled={!isProfileViewAnimationEnabled}
          aria-label={`${
            isProfileViewExpanded ? 'Close' : 'Open'
          } profile view`}
          onClick={() => toggleProfileView()}
        >
          <UserAvatar
            className={clsm([
              'duration-[400ms]',
              'group-focus:ring-0',
              isProfileViewAnimationEnabled &&
                (isProfileViewExpanded
                  ? [
                      'group-focus:hover:ring-4',
                      'hover:ring-4',
                      'hover:scale-[calc(27/25)]'
                    ]
                  : [
                      'group-focus:hover:ring',
                      'hover:ring',
                      'hover:scale-[calc(12/11)]'
                    ])
            ])}
            avatarSrc={avatarSrc}
            profileColor={color}
            size={isProfileViewExpanded ? 'xl' : 'lg'}
          />
        </button>
      </motion.div>
      <PlayerOverlay
        isGradientVisible={!isProfileViewExpanded}
        isVisible={shouldShowHeaderOverlay}
        position="top"
      >
        <motion.div
          {...getPlayerHeaderProfileViewAnimationProps({
            expanded: { y: 184, desktop: { y: 168 } },
            collapsed: { y: 0 }
          })}
          className={clsm(
            'flex',
            'items-center',
            'w-full',
            isProfileViewExpanded
              ? ['justify-center', 'flex-col']
              : ['justify-between', 'flex-row']
          )}
        >
          <motion.h3
            {...getPlayerHeaderProfileViewAnimationProps({
              expanded: { marginLeft: 0, height: 22 },
              collapsed: { marginLeft: 64, height: 44 }
            })}
            layout="position"
            layoutDependency={layoutDependency.current}
            className={clsm([
              'flex',
              'items-center',
              'max-w-full',
              'truncate',
              'transition-colors',
              'duration-[400ms]',
              'dark:text-white',
              isProfileViewExpanded ? 'text-black' : 'text-white'
            ])}
          >
            {username}
          </motion.h3>
          <motion.div
            layout="position"
            layoutDependency={layoutDependency.current}
            className={clsm(
              getHeaderButtonClasses(shouldRemoveFollowButtonZIndex)
            )}
            {...getPlayerHeaderProfileViewAnimationProps({
              expanded: { marginTop: 24, marginLeft: 0 },
              collapsed: { marginTop: 0, marginLeft: 16 }
            })}
          >
            {/* <FollowButton
              isExpandedView={isProfileViewExpanded}
              setFollowButtonRefState={setFollowButtonRefState}
            /> */}
             <div className="flex bottom-0">
          

          <button
            type="tip"
            ref={buttonRef}
            onClick={toggleModal}
            className="block text-white bg-[#ED2227] mx-auto hover:bg-[#76D0FC] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          >
            Add Tokens
          </button>
          {isOpen && (
          <div
            ref={buttonRef}
            className={clsm([
              isOpen ? 'fixed' : 'fixed',
              'flex',
              'flex-col',
              'items-end',
              'space-y-4',
              'left-[50%]',
              'top-1/2',
              '-translate-y-1/2',
              '-translate-x-1/2',
              'h-auto',
              'w-[35rem]',
              'rounded-md',
              "bg-white",
              'border',
              'border-white',
              'max-h-[450px]', // Fallback
              'max-h-[min(650px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_32px))]', // Mobile (portrait) max-height calculation
              isLandscape && [
                'md:max-w-[400px]',
                'touch-screen-device:max-w-[400px]'
              ]
            ])}
          >
            <div
              className={clsm([
                'max-h-[min(650px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_72px))]',
                'w-full',
                isOpen && ['bottom-12', 'right-[52px]', 'sm:right-9'],
                isOpen ? 'z-[1000]' : 'w-full'
              ])}
            >
              <motion.div
                {...createAnimationProps({
                  animations: ['fadeIn-half', 'scale']
                })}
                className={clsm([
                  'px-0',
                  'rounded-3xl',
                  'dark:bg-white',
                  'bg-lightMode-white',
                  'no-scrollbar',
                  'overflow-y-auto',
                  'overflow-x-hidden',
                  'supports-overlay:overflow-y-overlay'

                  // menuClassName
                ])}
                // ref={buttonRef}
              >
                <div
                  className={clsm([
                    'w-full',
                    'h-full',
                    'px-3',
                    'py-4',
                    'flex',
                    'flex-col',
                    'space-y-4',
                    'overflow-y-auto',
                    'overflow-x-hidden',
                    'supports-overlay:overflow-y-overlay'
                  ])}
                >
                  <div className="bg-black flex items-center justify-between h-14 px-4 ">
                    <h3 className="flex items-center justify-center  w-full text-gray-100 ">
                      Buy Tokens
                    </h3>
                  </div>
<p
className="flex items-center justify-center  w-full  "
>
  Live creator's receive 50% more payout on all token tips
</p>
                  {/* CUSTOM NAVIGATION BUTTONS */}
                  <div className="flex flex-col gap-2 w-full h-[300px] overflow-y-scroll">
                    
                      {TipList.map(({ value, label, tipContent }) => (
                      <React.Fragment key={label}>
                        <div
                          onClick={() => {
                            settipCost(value);
                          }}
                          className={`w-[300px] h-12  mx-auto hover:bg-[#76D0FC] rounded-12 hover:cursor-pointer flex  justify-between p-2 text-black rounded-xl  ${
                            tipCost === value ? 'bg-[#76D0FC]' : 'bg-gray-200'
                          }  `}
                        >
                          <span className=" font-bold text-black">{label} TKs</span>
                          <span className=" text-gray-700">{tipContent}</span>
                        </div>
                      </React.Fragment>
                    ))}
                    
                   

                    
                  </div>

                  <Button
                    className="bg-[#ED2227] hover:opacity-80 text-white h-10 w-[12rem] mx-auto"
                    onClick={() => {
                      setIsOpen(false);
                      settipCost('');
                    }}
                  >
                    <p className="text-white hover:text-black">Buy Now</p>
                  </Button>
                </div>
              </motion.div>
            </div>
          </div>
        )}
          </div>
            <motion.div
              className={clsm(['w-11', 'h-11'])}
              {...getPlayerHeaderProfileViewAnimationProps({
                expanded: {
                  width: 'auto',
                  opacity: 1,
                  display: 'block',
                  transition: {
                    ...DEFAULT_PROFILE_VIEW_TRANSITION,
                    duration: animationDuration / 2,
                    delay: animationDuration
                  }
                },
                collapsed: {
                  width: 0,
                  opacity: 0,
                  transition: {
                    ...DEFAULT_PROFILE_VIEW_TRANSITION,
                    opacity: { duration: animationDuration / 4 }
                  },
                  transitionEnd: { display: 'none' }
                }
              })}
            >
              <ProfileViewMenu
                channelUsername={username}
                isSessionValid={isSessionValid}
              />
            </motion.div>
          </motion.div>
        </motion.div>
      </PlayerOverlay>
    </div>
  );
};

PlayerHeader.defaultProps = {
  avatarSrc: '',
  color: 'default',
  username: ''
};

PlayerHeader.propTypes = {
  avatarSrc: PropTypes.string,
  color: PropTypes.string,
  openPopupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  username: PropTypes.string
};

export default PlayerHeader;
