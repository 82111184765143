import { encode } from 'html-entities';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import {
  COMPOSER_MAX_CHARACTER_LENGTH,
  COMPOSER_RATE_LIMIT_BLOCK_TIME_MS
} from '../../../constants';
import { CHAT_MESSAGE_EVENT_TYPES } from '../../../constants';
import { channel as $channelContent } from '../../../content';
import { CHAT_USER_ROLE, SEND_ERRORS } from './useChatConnection/utils';
import { clsm } from '../../../utils';
import { Lock } from '../../../assets/icons';
import { useChannel } from '../../../contexts/Channel';
import { useResponsiveDevice } from '../../../contexts/ResponsiveDevice';
import { useUser } from '../../../contexts/User';
import ComposerErrorMessage from './ComposerErrorMessage';
import FloatingNav from '../../../components/FloatingNav';
import Input from '../../../components/Input';
import useCurrentPage from '../../../hooks/useCurrentPage';
import { usePoll } from '../../../contexts/StreamManagerActions/Poll';
import { createAnimationProps } from '../../../helpers/animationPropsHelper';
import Button from '../../../components/Button';
const TipListToken = [
  {
    value: 10,
    label: '10',
    tipContent: '$10'
  },
  {
    value: 25,
    label: '25',
    tipContent: '$25'
  },
  {
    value: 50,
    label: '50',
    tipContent: '$50'
  },
  {
    value: 100,
    label: '100',
    tipContent: '$100'
  },
  {
    value: 150,
    label: '150',
    tipContent: '$150'
  },
  {
    value: 200,
    label: '200',
    tipContent: '$200'
  },
  {
    value: 500,
    label: '500',
    tipContent: '$500'
  },
  {
    value: 600,
    label: '600',
    tipContent: '$600'
  },
  {
    value: 700,
    label: '700',
    tipContent: '$700'
  },
  {
    value: 800,
    label: '800',
    tipContent: '$800'
  }
];
const TipList = [
  {
    value: 10,
    label: '$10',
    tipContent: 'Show Love'
  },
  {
    value: 25,
    label: '$25',
    tipContent: 'Make it Rain'
  },
  {
    value: 50,
    label: '$50',
    tipContent: 'Spank x5'
  },
  {
    value: 100,
    label: '$100',
    tipContent: 'suck nipple'
  },
  {
    value: 150,
    label: '$150',
    tipContent: 'suck dildo'
  },
  {
    value: 200,
    label: '$200',
    tipContent: 'Dildo pussy'
  },
  {
    value: 500,
    label: '$500',
    tipContent: 'Hardcore fuck'
  },
  {
    value: 1000,
    label: '$1000',
    tipContent: 'my wish'
  },
  {
    value: 2000,
    label: '$2000',
    tipContent: 'make me happy'
  },
  {
    value: 15,
    label: '$15',
    tipContent: 'control the lush 5 minutes'
  },
  {
    value: 30,
    label: '$30',
    tipContent: 'rain of squirt'
  },
  {
    value: 50,
    label: '$50',
    tipContent: 'Say my name'
  },
  {
    value: 60,
    label: '$60',
    tipContent: 'write my name anywhere on the body'
  },
  {
    value: 20,
    label: '$20',
    tipContent: 'make me feel very horny'
  },
  {
    value: 30,
    label: '$30',
    tipContent: 'use the strap on'
  },
  {
    value: 40,
    label: '$40',
    tipContent: 'show boobs mary'
  }
];
export const MENU_BUTTON_CLASSES = [
  'px-3',
  'py-1.5',
  'text-black',
  'dark:text-white',
  'dark:hover:bg-darkMode-gray-hover',
  'flex-shrink-0',
  '[&>svg]:w-6',
  '[&>svg]:h-6',
  '[&>svg]:flex-shrink-0'
];

export const HAIRLINE_DIVIDER_CLASSES = [
  'w-full',
  'h-0.5',
  'rounded',
  'flex-shrink-0',
  'bg-lightMode-gray-light-hover',
  'dark:bg-darkMode-gray-hover'
];
const { SEND_MESSAGE } = CHAT_MESSAGE_EVENT_TYPES;

const $content = $channelContent.chat;

const Composer = ({
  chatUserRole,
  isDisabled,
  isFocusable,
  isLoading,
  sendAttemptError,
  sendMessage
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const composerFieldRef = useRef();
  const { channelData } = useChannel();
  const { isViewerBanned: isLocked } = channelData || {};
  const { isLandscape } = useResponsiveDevice();
  const { isSessionValid } = useUser();
  const { setComposerRefState } = usePoll();
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [shouldShake, setShouldShake] = useState(false); // Composer has shake animated only on submit
  const [blockChat, setBlockChat] = useState(false);
  const [tipCost, settipCost] = useState('');
  const [CustomTip, setCustomTip] = useState(false);
  const [tipAmount, settipAmount] = useState('');
  const [tipDescription, settipDiscription] = useState('');

  const profileMenuRef = useRef();

  const currentPage = useCurrentPage();
  const isStreamManagerPage = currentPage === 'stream_manager';

  const canSendMessages =
    chatUserRole &&
    [CHAT_USER_ROLE.SENDER, CHAT_USER_ROLE.MODERATOR].includes(chatUserRole);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenToken, setIsOpenToken] = useState(false);

  const [isOpenPrivate, setIsOpenPrivate] = useState(false);

  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const buttonRef = useRef(null);
  const buttonRefToken = useRef(null);

  const buttonPrivateRef = useRef(null);

  const toggleModal = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setModalPosition({
        top: rect.top + window.scrollY - rect.height, // Position above the button
        left: rect.left + window.scrollX
      });
    }
    setIsOpen(!isOpen);
  };
  const toggleModalToken = () => {
    setIsOpenToken(!isOpenToken);

    if (buttonRefToken.current) {
      const rect = buttonRefToken.current.getBoundingClientRect();
      setModalPosition({
        top: rect.top + window.scrollY - rect.height, // Position above the button
        left: rect.left + window.scrollX
      });
    }
  };
  const toggleModalPrivate = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setModalPosition({
        top: rect.top + window.scrollY - rect.height, // Position above the button
        left: rect.left + window.scrollX
      });
    }
    setIsOpenPrivate(!isOpenPrivate);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpenToken &&
        buttonRefToken.current &&
        !buttonRefToken.current.contains(event.target)
      ) {
        setIsOpenToken(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenToken]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpenPrivate &&
        buttonPrivateRef.current &&
        !buttonPrivateRef.current.contains(event.target)
      ) {
        setIsOpenPrivate(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenPrivate]);


  const focus = location.state?.focus;

  useEffect(() => {
    if (composerFieldRef.current) {
      setComposerRefState(composerFieldRef);
    }
  }, [composerFieldRef, setComposerRefState]);

  const setSubmitErrorStates = (_errorMessage) => {
    setErrorMessage(`${$content.error.message_not_sent} ${_errorMessage}`);
    setShouldShake(true);
  };

  const navigateToLogin = () =>
    navigate('/login', { state: { from: location, focus: 'COMPOSER' } });

  const handleOnChange = (event) => {
    // If the user isn't logged in, redirect them to the login page
    if ((!isLoading && canSendMessages === false) || !isSessionValid) {
      navigateToLogin();
    }

    const { value } = event.target;
    const encodedValue = encode(value);
    // This is done to ensure we get the correct message length as it seems the IVS Chat API trims the message before checking its length
    const trimmedValue = encodedValue.trim();

    setMessage(value);

    // On change errors
    if (trimmedValue.length > COMPOSER_MAX_CHARACTER_LENGTH) {
      setErrorMessage($content.error.max_length_reached);
    } else if (!blockChat) {
      setErrorMessage('');
    }
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (isDisabled) return;

    if (isLoading) {
      setSubmitErrorStates($content.error.wait_until_connected);
    } else {
      if (canSendMessages) {
        if (!message || blockChat) return;
        if (errorMessage.includes($content.error.wait_until_connected)) {
          setErrorMessage('');
          setMessage('');
        }

        sendMessage(message, { eventType: SEND_MESSAGE });
        !errorMessage && setMessage('');
        setShouldShake(false);
      } else {
        if (canSendMessages === undefined) {
          setSubmitErrorStates($content.error.wait_until_connected);
        } else {
          navigateToLogin();
        }
      }
    }
  };

  useEffect(() => {
    // If previous route has focus state, focus on composer
    if (focus && focus === 'COMPOSER') {
      composerFieldRef.current.focus();
    }
  }, [focus]);

  useEffect(() => {
    // If user is banned, remove any message
    if (isLocked) setMessage('');
  }, [isLocked]);

  useEffect(() => {
    // If there is a connection error, clear the current error message
    if (isDisabled) setErrorMessage('');
  }, [isDisabled]);

  useEffect(() => {
    if (blockChat) {
      const blockChatTimerId = setTimeout(() => {
        setBlockChat(false);
        setErrorMessage('');
      }, COMPOSER_RATE_LIMIT_BLOCK_TIME_MS);

      return () => clearTimeout(blockChatTimerId);
    }
  }, [blockChat]);

  useEffect(() => {
    // Send errors
    if (sendAttemptError) {
      let sendAttemptErrorMessage = '';

      if (sendAttemptError.message === SEND_ERRORS.RATE_LIMIT_EXCEEDED) {
        setBlockChat(true);
        sendAttemptErrorMessage = $content.error.rate_exceeded;
      } else if (sendAttemptError.message === SEND_ERRORS.MAX_LENGTH_EXCEEDED) {
        sendAttemptErrorMessage = $content.error.max_length_reached;
      }

      // connection error or chat is loading (chat is not connected)
      setSubmitErrorStates(sendAttemptErrorMessage);
    }
  }, [sendAttemptError, isLoading]);

  const handleTipCost = (value) => {
    if (value === 0) {
    } else {
    }
  };
  return (
    <div className={clsm(['w-full', 'pt-5', 'pb-6', 'px-[18px]'])}>
      <motion.div
        animate={shouldShake ? 'shake' : 'default'}
        variants={{
          shake: { x: [12, -12, 8, -8, 4, 0] },
          default: { x: 0 }
        }}
        transition={{ duration: 0.5 }}
      >
        <form
          className={clsm(
            'relative',
            isSessionValid && [
              'md:w-[calc(100%_-_60px)]',
              isLandscape && 'touch-screen-device:lg:w-[calc(100%_-_60px)]'
            ]
          )}
          onSubmit={handleSendMessage}
        >
          <div>
            <ComposerErrorMessage errorMessage={errorMessage} />

            <div className="flex">
              <Input
                {...(!isFocusable ? { tabIndex: -1 } : {})}
                ariaLabel={isDisabled ? 'Chat disabled' : null}
                autoComplete="off"
                className={clsm(
                  [
                    'bg-lightMode-gray',
                    'dark:bg-darkMode-gray',
                    'dark:focus:text-white',
                    'dark:hover:bg-darkMode-gray-hover',
                    'dark:hover:placeholder-white',
                    'dark:hover:text-white',
                    'dark:placeholder-darkMode-gray-light',
                    'focus:bg-darkMode-gray-medium',
                    'h-12',
                    'placeholder-lightMode-gray-dark'
                  ],
                  errorMessage && [
                    'dark:focus:shadow-darkMode-red',
                    'dark:focus:shadow-focus',
                    'dark:shadow-darkMode-red',
                    'focus:shadow-lightMode-red',
                    'rounded-b-3xl',
                    'rounded-t-none',
                    'shadow-lightMode-red'
                  ],
                  isLocked && [
                    'dark:read-only:focus:bg-darkMode-gray',
                    'dark:read-only:hover:bg-darkMode-gray',
                    'dark:read-only:hover:placeholder-darkMode-gray-light',
                    'pr-[60px]',
                    'read-only:cursor-not-allowed',
                    'read-only:focus:bg-lightMode-gray',
                    'read-only:focus:shadow-none',
                    'read-only:hover:bg-lightMode-gray',
                    'read-only:hover:placeholder-lightMode-gray-dark',
                    'read-only:hover:placeholder-lightMode-gray-medium'
                  ],
                  isDisabled && ['opacity-30']
                )}
                error={errorMessage ? '' : null}
                isRequired={false}
                name="chatComposer"
                onChange={handleOnChange}
                placeholder={
                  isLocked ? $content.you_are_banned : $content.say_something
                }
                readOnly={isDisabled || isLocked}
                ref={composerFieldRef}
                value={message}
              />
            </div>
            {isLocked && (
              <span
                className={clsm([
                  '[&>svg]:fill-lightMode-gray-medium',
                  '[&>svg]:h-6',
                  '[&>svg]:w-6',
                  'absolute',
                  'bottom-3',
                  'cursor-not-allowed',
                  'dark:[&>svg]:fill-darkMode-gray-light',
                  'dark:fill-darkMode-gray-light',
                  'right-6',
                  'top-3'
                ])}
              >
                <Lock />
              </span>
            )}
          </div>
        </form>
      </motion.div>
      {console.log(isOpenToken,"isOpenToken")}
      {isOpenToken && (
          <div
            ref={buttonRefToken}
            className={clsm([
              isOpenToken ? 'fixed' : 'fixed',
              'flex',
              'flex-col',
              'items-end',
              'space-y-4',
              'left-[50%]',
              'top-1/2',
              '-translate-y-1/2',
              '-translate-x-1/2',
              'h-auto',
              'w-[35rem]',
              'rounded-md',
              'bg-white',
              'border',
              'border-white',
              'max-h-[450px]', // Fallback
              isOpenToken ? 'z-[1000]' : 'w-full',
              'max-h-[min(650px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_32px))]', // Mobile (portrait) max-height calculation
              isLandscape && [
                'md:max-w-[400px]',
                'touch-screen-device:max-w-[400px]'
              ]
            ])}
          >
            <div
              className={clsm([
                'max-h-[min(650px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_72px))]',
                'w-full',
                isOpenToken && ['bottom-12', 'right-[52px]', 'sm:right-9'],
                isOpenToken ? 'z-[1000]' : 'w-full'
              ])}
            >
              <motion.div
                {...createAnimationProps({
                  animations: ['fadeIn-half', 'scale']
                })}
                className={clsm([
                  'px-0',
                  'rounded-3xl',
                  'dark:bg-white',
                  'bg-lightMode-white',
                  'no-scrollbar',
                  'overflow-y-auto',
                  'overflow-x-hidden',
                  'supports-overlay:overflow-y-overlay'

                ])}
              >
                <div
                  className={clsm([
                    'w-full',
                    'h-full',
                    'px-3',
                    'py-4',
                    'flex',
                    'flex-col',
                    'space-y-4',
                    'overflow-y-auto',
                    'overflow-x-hidden',
                    'supports-overlay:overflow-y-overlay'
                  ])}
                >
                  <div className="bg-black flex items-center justify-between h-14 px-4 ">
                    <h3 className="flex items-center justify-center  w-full text-gray-100 ">
                      Buy Tokens
                    </h3>
                  </div>
                  <p className="flex items-center justify-center  w-full  ">
                    Live creator's receive 50% more payout on all token tips
                  </p>
                  <div className="flex flex-col gap-2 w-full h-[300px] overflow-y-scroll">
                    {TipListToken.map(({ value, label, tipContent }) => (
                      <React.Fragment key={label}>
                        <div
                          onClick={() => {
                            settipCost(value);
                          }}
                          className={`w-[300px] h-12  mx-auto hover:bg-[#76D0FC] rounded-12 hover:cursor-pointer flex  justify-between p-2 text-black rounded-xl  ${
                            tipCost === value ? 'bg-[#76D0FC]' : 'bg-gray-200'
                          }  `}
                        >
                          <span className=" font-bold text-black">
                            {label} TKs
                          </span>
                          <span className=" text-gray-700">{tipContent}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>

                  <Button
                    className="bg-[#ED2227] hover:opacity-80 text-white h-10 w-[12rem] mx-auto"
                    onClick={() => {
                      setIsOpenToken(false);
                      settipCost('');
                    }}
                  >
                    <p className="text-white hover:text-black">Buy Now</p>
                  </Button>
                </div>
              </motion.div>
            </div>
          </div>
        )}
        {!isStreamManagerPage &&
      <div className="flex gap-1 m-2">
        <button
          type="tip"
          ref={buttonPrivateRef}
          onClick={toggleModalPrivate}
          className="block text-white bg-[#ED2227] mx-auto hover:bg-[#76D0FC] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
        >
          Go private
        </button>
        {isOpenPrivate && (
          <div
            ref={buttonPrivateRef}
            className={clsm([
              isOpenPrivate ? 'fixed' : 'absolute',
              'flex',
              'flex-col',
              'items-end',
              'space-y-4',
              'left-[40%]',
              '-translate-x-1/2',
              'z-[999]',
              'bottom-8',
              'h-auto',
              'w-[35rem]',
              'rounded-md',
              'bg-black',
              'border',
              'border-white',
              'max-h-[250px]', // Fallback
              'max-h-[min(650px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_32px))]', // Mobile (portrait) max-height calculation
              isLandscape && [
                'md:max-w-[400px]',
                'touch-screen-device:max-w-[400px]'
              ]
            ])}
          >
            <div
              className={clsm([
                'max-h-[min(650px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_72px))]',
                'w-full',
                isOpenPrivate && ['bottom-12', 'right-[52px]', 'sm:right-9'],
                isOpenPrivate ? 'z-[1000]' : 'w-auto'
              ])}
            >
              <motion.div
                {...createAnimationProps({
                  animations: ['fadeIn-half', 'scale']
                })}
                className={clsm([
                  'px-0',
                  'rounded-3xl',
                  'dark:bg-black/50',
                  'bg-lightMode-white',
                  'no-scrollbar',
                  'overflow-y-auto',
                  'overflow-x-hidden',
                  'supports-overlay:overflow-y-overlay'

                  // menuClassName
                ])}
                // ref={buttonRef}
              >
                <div
                  className={clsm([
                    'w-full',
                    'h-full',
                    'px-0',
                    'py-0',
                    'flex',
                    'flex-col',
                    'space-y-4',
                    'overflow-y-auto',
                    'overflow-x-hidden',
                    'supports-overlay:overflow-y-overlay'
                  ])}
                >
                  <div className="bg-black flex items-center justify-between h-14 px-4">
                    <h3 className="flex items-center justify-start  w-full text-gray-100">
                      Go Private
                    </h3>
                    {/* <h3
                          className='flex items-center justify-center mx-auto  w-full'
                          >is anonymous</h3> */}
                  </div>

                  {/* CUSTOM NAVIGATION BUTTONS */}
                  <div className="flex flex-col gap-2 w-full h-auto p-4">
                    <div className="flex flex-wrap justify-between items-center gap-4">
                      <div className=" flex-none w-[6rem] h-[6rem] rounded-full bg-white overflow-hidden"></div>
                      <div className="flex-1 text-gray-100">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-white px-4 py-2 flex items-center justify-between gap-2 text-gray-100">
                    <div>You have 0 Tks</div>
                    <div>
                      <Button
                        ref={buttonRefToken}
                        className="bg-[#ED2227] hover:opacity-80 text-white h-10"
                        onClick={() => {
                          setIsOpenPrivate(false);
                          setIsOpenToken(true);
                          toggleModalToken();
                          settipCost('');
                        }}
                      >
                        <p className="text-white hover:text-black">
                          Add Tokens
                        </p>
                      </Button>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        )}
        
        <button
          type="tip"
          ref={buttonRef}
          onClick={toggleModal}
          className="block text-white bg-[#ED2227] mx-auto hover:bg-[#76D0FC] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
        >
          Give tip
        </button>
        {isOpen && (
          <div
            ref={buttonRef}
            className={clsm([
              isOpen ? 'fixed' : 'absolute',
              'flex',
              'flex-col',
              'items-end',
              'space-y-4',
              'right-5',
              'bottom-6',
              'h-auto',
              'w-[350px]',
              'border-2 border-gray-300 rounded-lg',
              'bg-white',
              'max-h-[650px]', // Fallback
              'max-h-[min(650px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_32px))]', // Mobile (portrait) max-height calculation
              isLandscape && [
                'md:max-w-[400px]',
                'touch-screen-device:max-w-[400px]'
              ]
            ])}
          >
            <div
              className={clsm([
                'max-h-[min(650px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_72px))]',
                isOpen && ['bottom-12', 'right-[52px]', 'sm:right-9'],
                isOpen ? 'z-[1000]' : 'w-auto'
              ])}
            >
              <motion.div
                {...createAnimationProps({
                  animations: ['fadeIn-half', 'scale']
                })}
                className={clsm([
                  'px-1',
                  'rounded-3xl',
                  'dark:bg-white',
                  'bg-lightMode-white',
                  'no-scrollbar',
                  'overflow-y-auto',
                  'overflow-x-hidden',
                  'supports-overlay:overflow-y-overlay'

                  // menuClassName
                ])}
                // ref={buttonRef}
              >
                <div
                  className={clsm([
                    'w-full',
                    'h-full',
                    'px-3',
                    'py-4',
                    'flex',
                    'flex-col',
                    'space-y-4',
                    'overflow-y-auto',
                    'overflow-x-hidden',
                    'supports-overlay:overflow-y-overlay'
                  ])}
                >
                  <div className="bg-gray-200 flex items-center justify-between h-14 rounded-lg">
                    <h3 className="flex items-center justify-center mx-auto  w-full">
                      TIP MENU
                    </h3>
                    {/* <h3
                          className='flex items-center justify-center mx-auto  w-full'
                          >is anonymous</h3> */}
                    <div class="flex items-center ">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        value=""
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="default-checkbox"
                        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {' '}
                        Anonymous
                      </label>
                      <button
                        data-tooltip-target="tooltip-no-arrow"
                        type="button"
                        class="text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center   "
                      >
                        <IoIosInformationCircleOutline className="text-black" />
                      </button>
                      <div
                        id="tooltip-no-arrow"
                        role="tooltip"
                        class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                      >
                        is anonymous
                      </div>
                    </div>
                  </div>
                  <span className={clsm(HAIRLINE_DIVIDER_CLASSES)} />

                  {/* CUSTOM NAVIGATION BUTTONS */}
                  <div className="flex flex-col gap-2 w-full h-[300px] overflow-y-scroll">
                    <div
                      onClick={() => {
                        setCustomTip(!CustomTip);
                      }}
                      className={`w-[300px] h-12 bg-gray-200 mx-auto  rounded-12 hover:cursor-pointer flex items-center justify-center p-2 text-black rounded-xl 
                                   
                                  }  `}
                    >
                      <span>Custom Tip</span>
                    </div>
                    {CustomTip && (
                      <div
                        // onClick={() => {
                        //   settipCost(value);
                        // }}
                        className={`w-[300px] h-12 bg-gray-200 mx-auto  rounded-12 hover:cursor-pointer flex items-center justify-start p-2 text-black rounded-xl 
                                   
                                  }  `}
                      >
                        <input
                          type="text"
                          value={tipAmount}
                          onChange={(e) => {
                            settipAmount(e.target.value);
                          }}
                          className="w-1/6 h-10 border-2 border-gray-300 rounded-xl p-2"
                        />

                        <span className="w-1/6 text-gray-700">TKs</span>
                        <input
                          type="text"
                          value={tipDescription}
                          onChange={(e) => {
                            settipDiscription(e.target.value);
                          }}
                          placeholder="Your message (optional)"
                          className="w-4/6 h-10 border-2 border-gray-300 rounded-xl p-2"
                        />
                      </div>
                    )}
                    {TipList.map(({ value, label, tipContent }) => (
                      <React.Fragment key={label}>
                        {console.log(label, value, tipCost, 'checkeck')}
                        <div
                          onClick={() => {
                            settipCost(value);
                          }}
                          className={`w-[300px] h-12  mx-auto hover:bg-[#76D0FC] rounded-12 hover:cursor-pointer flex items-center justify-start p-2 text-black rounded-xl  ${
                            tipCost === value ? 'bg-[#76D0FC]' : 'bg-gray-200'
                          }  `}
                        >
                          <span className="w-1/3 font-bold text-black">
                            {label}
                          </span>
                          <span className="w-2/3 text-gray-700">
                            {tipContent}
                          </span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>

                  <Button
                    className="bg-gray-200"
                    onClick={() => {
                      setIsOpen(false);
                      settipCost('');
                    }}
                  >
                    <p className="text-black">Tip</p>
                  </Button>
                </div>
              </motion.div>
            </div>
          </div>
        )}
      </div>
}

      <FloatingNav
        {...(isStreamManagerPage && {
          containerClassName: (isOpen) =>
            clsm([
              'max-h-[min(650px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_72px))]',
              isOpen && ['bottom-12', 'right-[52px]', 'sm:right-9']
            ]),
          menuClassName: clsm([
            'lg:w-[calc(100vw_-_104px)]',
            'sm:w-[calc(100vw_-_72px)]',
            isLandscape && [
              'fixed',
              'max-h-[min(570px,calc(calc(var(--mobile-vh,1vh)_*_100)_-_126px))]',
              'right-[52px]',
              'sm:right-[36px]',
              'sm:w-full',
              'max-w-[400px]',
              'bottom-[112px]',
              'sm:w-[calc(100vw_-_72px)]'
            ]
          ])
        })}
      />
    </div>
  );
};

Composer.defaultProps = {
  chatUserRole: undefined,
  isDisabled: false,
  isFocusable: true,
  isLoading: true,
  sendAttemptError: null
};

Composer.propTypes = {
  chatUserRole: PropTypes.oneOf(Object.values(CHAT_USER_ROLE)),
  isDisabled: PropTypes.bool,
  isFocusable: PropTypes.bool,
  isLoading: PropTypes.bool,
  sendAttemptError: PropTypes.shape({ message: PropTypes.string }),
  sendMessage: PropTypes.func.isRequired
};

export default Composer;
